@import "bourbon";

html {
    height: 100%;
}

body {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 8pt;
    padding: 0;
    margin: 0;
    @include background(linear-gradient(#69d0bf, #7ddd6d) left no-repeat fixed);

    #header {
        //height: 40px;
        background-color: #fff;
        text-align: center;

        h1 {
            margin: 0;
            padding: 10px;
            font-size: 12pt;

            span {
                color: #ddd;
            }
            strong {
                color: #3d3d3d;
                font-weight: 500;
            }

        }

    }

    .person {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        border: 1px solid #aaa;
        display: inline-block;
        overflow: hidden;
        text-align: center;
        img {
            margin: 0 auto;
            max-height: 100%;
            max-width: 100%;
        }
    }

    #tweedekamer {
        position: relative;
        margin: 0 auto;
        margin-top: 20px;
        //background: url('https://www.tweedekamer.nl/sites/default/files/styles/content_image/public/atoms/images/zetelverdeling_april_2015_0.jpg?itok=FDwFjDwu') no-repeat -40px -190px;
        width: 700px;
        height: 350px;

        .seat {
            position: absolute;
            $size: 20px;
            width: $size;
            height: $size;
            border-radius: $size / 3;
            border: 2px solid rgba(255, 255, 255, 0.8);
            box-sizing: border-box;
            background-color: #aaa;
            opacity: 0.8;
            box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px;
            &.active {
                opacity: 1;
                box-shadow: rgba(0, 0, 0, 0.4) 0 0 10px;
            }
        }
    }

    .padding {
        padding: 10px;
    }
    button, .button {
        @include button(pill);
    }
    .controls button {
        @include button(pill, #fff);
    }

    #wrapper {
        padding: 30px 0;
    }

    .party {
        width: 190px;
        //min-height: 600px;
        height: 220px;
        vertical-align: top;
        box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
        box-sizing: border-box;
        background-color: #fff;
        margin: 8px;
        float: left;
        overflow: hidden;

        &:hover, &.active {
            box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
        }

        &.inactive {
            opacity: 0.5;
        }

        .top-border {
            width: 100%;
            height: 6px;
        }
        .top {
            width: 100%;
            height: 44px;
            opacity: 0.1;
            z-index: 0;
        }
        .top-heading {
            height: 44px;
        }
        h1 {
            margin-top: -44px;
            margin-bottom: 0;
            padding: 5px;
            font-weight: 100;
            box-sizing: border-box;
            font-size: 12pt;

            .founding-year {
                font-size: 8pt;
                opacity: 0.6;
                //line-height: 19pt;
            }
        }
        h2 {
            margin-top: -10px;
            margin-bottom: 0;
            padding: 5px;
            font-weight: 400;
            font-size: 7pt;
        }
        a {
            color: #000;
            text-decoration: none;
            font-weight: 700;

            &:hover {
                opacity: 0.8;
            }
        }
        a.program {
            background-color: #eee;
        }
        .block {
            display: block;
            box-sizing: border-box;
            padding: 5px;
            white-space: normal;
            z-index: 10;
        }
        .social {
            background-color: #ddd;
            div {
                font-size: 11pt;
                margin-top: -2px;
            }
        }
        .info {
            flex: 0 1 auto;
        }
        .news {
            overflow: auto;
            line-height: 1em;
            flex: 0 1 auto;

            .news-item {
                text-align: justify;
                font-size: 9pt;
                color: #333;
                cursor: pointer;

                .provider {
                    color: #888;
                    line-height: 1.5em;
                }
                .date {
                    font-weight: 100;
                }
            }
            .news-item:hover {
                background-color: #f5f5f5;
            }
        }

        .seats {

            table {
                width: 100%;
                text-align: center;
                border-collapse: collapse;
            }

            .seats-icon {
                //border: 1px solid #888;
                color: #888;
                font-size: 7pt;
                padding: 2px;
                border-radius: 8px;
                margin-left: 6px;
            }
            .seats-icon:first-child {
                margin-left: 0;
            }

        }

    }
    .edit-parties {
        table {
            border-collapse: collapse;
        }
        td {
            width: 200px;
        }
        input {
            height: 100%;
            width: 100%;
        }
    }
}